import React from 'react'
import Helmet from 'react-helmet'
import '../../assets/scss/init.scss'
import './style.scss'

const STATUS = {
  STARTED: 'Started',
  STOPPED: 'Stopped',
}



const Layout = (props) => {
    const { children } = props

    // const initialSeconds = (new Date("2021-11-27T00:00:00.000+02:00") - Date.now()) / 1000;

    // const [secondsRemaining, setSecondsRemaining] = React.useState(initialSeconds);
    // const [status, setStatus] = React.useState(STATUS.STARTED);
  
    // const secondsToDisplay = secondsRemaining % 60;
    // const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60;
    // const minutesToDisplay = minutesRemaining % 60;
    // const hoursToDisplay = (minutesRemaining - minutesToDisplay) / 60;

    // // source: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
    // function useInterval(callback, delay) {
    //   const savedCallback =  React.useRef()

    //   // Remember the latest callback.
    //   React.useEffect(() => {
    //     savedCallback.current = callback
    //   }, [callback])

    //   // Set up the interval.
    //   React.useEffect(() => {
    //     function tick() {
    //       savedCallback.current()
    //     }
    //     if (delay !== null) {
    //       let id = setInterval(tick, delay)
    //       return () => clearInterval(id)
    //     }
    //   }, [delay])
    // }

    // // https://stackoverflow.com/a/2998874/1673761
    // const twoDigits = (num) => String(num).padStart(2, '0')

    // useInterval(
    //   () => {
    //     if (secondsRemaining > 0) {
    //       setSecondsRemaining(secondsRemaining - 1)
    //     } else {
    //       setStatus(STATUS.STOPPED)
    //     }
    //   },
    //   status === STATUS.STARTED ? 1000 : null,
    //   // passing null stops the interval
    // )

    // const bannerContent = (
    //   <p style={{color: "#FFF"}}>
    //     Join St. Markos Scouts - <a href="https://stmarkos-scout.org/posts/211411-join-the-scouts/" style={{color: "#FFF"}}>Read more</a> - <a style={{color: "#FFF"}} href="https://forms.gle/w3wJF4kKSLJyERE49">Sign up now!</a> - Application closed in <strong>{twoDigits(Math.round(hoursToDisplay))}:{twoDigits(Math.round(minutesToDisplay))}:{twoDigits(Math.round(secondsToDisplay))}</strong>
    //   </p>);

    return (
      <div>
        <div className="banner" style={{float: "none"}}>
          <div className="banner-content">
            <center>
              <p style={{color: "#FFF"}}>
                Join St. Markos Scouts - <a href="https://stmarkos-scout.org/posts/211411-join-the-scouts/" style={{color: "#FFF"}}>Read more</a> - Application is now closed
              </p>
            </center>
          </div>
        </div>
        <div className="layout" style={{marginTop: "40px"}}>
          <Helmet defaultTitle="St Markos Scouts Official Website" />
          {children}
        </div>
      </div>
    );
};

export default Layout

